<template>
  <section>
      <div class="content-header">
        <h2>Why discuss resilience with students?</h2>
      </div>
      <div class="content-wrapper">
        <p>Research shows that how students set goals for themselves, known as their “goal orientation”, influence their cognitive, affective and behavioural reactions as well as their academic performance. </p>
        <p>Resilient students are able to stay highly motivated despite stressful events and conditions that may arise. </p>
        <p>In other words, motivation is key to academic resilience.  </p>
        <p>Take time to discuss the importance of setting (and adjusting) goals with your students, who may be unaware of the direct link between goal orientation and academic resilience. </p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
